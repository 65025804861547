@import url("https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700&display=swap");

html {
  /* overflow: auto; */
  width: 100vw;
  min-width: 100vw;
}
body {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-width: 100vw;
  overflow: auto;
  height: auto;
}
ul li {
  list-style-type: none;
}
img {
  color: transparent;
}
input {
  /* background: transparent; */
  background: transparent;
  border: none;
  cursor: pointer;
  user-select: none;
  outline: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #a1b9d9;
  z-index: 1;
}
.player-wrapper {
  background: pink;
}
#root {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: fit-content;
}
.leftNavActive {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 22px;
  cursor: pointer;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #d5e3f5;
  height: 41px;
  min-height: 41px;
  background: #537093;
  /* margin-left: 6px;
  margin-right: 6px;
  border-radius: 5px; */
}
.leftNavActive:hover {
  color: #d5e3f5;
}
.leftNavInActive {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 22px;
  cursor: pointer;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #c7daef;
  height: 41px;
  min-height: 41px;
}
.leftNavInActive:hover {
  color: #d5e3f5;
}

.leftNavActiveCustom {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 22px;
  cursor: pointer;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  color: #d5e3f5;
  background: #244771;
  width: 100%;
  /* line-height: 18px; */
  /* height: 28px; */
}
.leftNavInActiveCustom {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 22px;
  cursor: pointer;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  color: #7399c4;
  width: 100%;
  /* line-height: 18px; */
  /* height: 27px   */
}
.leftNavInActiveCustom:hover {
  background: #244771;
  color: #d5e3f5;
}
.NTLogo {
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 29px;
  cursor: pointer;
  color: #9cb1cd;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
}
.NTLogo:hover {
  color: #9cb1cd;
}
/* .reactPlayer {
  position: relative;
  top: 0;
  right: 0; 
  width:450px;
  height: 230px;
} */
/* .video-icon {
  opacity:1;
  transition: opacity 0.1s;
} */
.react-datepicker__tab-loop {
  z-index: 26;
}
.ant-slider-track {
  background: #ff5983;
}
/* .infinite-scroll-component__outerdiv {
  height: 100%;
} */
.ant-modal-content {
  border-radius: 1px;
  min-height: 600px;
}
.ant-modal-body {
  min-height: 600px;
}

.ant-slider-handle {
  border: solid 2px #ff5983;
  border-color: #ff5983;
}
.ant-slider-handle:focus {
  border-color: #ff5983;
}
.ant-slider-handle:hover {
  border-color: #ff5983;
}
.ant-slider:hover .ant-slider-track {
  background: #ff5983;
  border-color: #ff5983;
}
.ant-slider:hover {
  border-color: #ff5983;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #64b9ff;
  border-color: #64b9ff;
}
.ant-modal-close {
  z-index: 0;
}
/* #zmmtg-root {
  width: 100%;
  height: 100%;
  display: none;
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
} */

.ais-Highlight {
  text-align: left;
}
/* Algolia Styling */
.ais-Highlight-highlighted {
  background: yellow;
}
.ais-InfiniteHits-item {
  cursor: pointer;
  padding: 15px;
  padding-right: 24px;
  background: #fff;
  width: 100%;
  border-top: 1px solid #e2e2e2;
}

.ais-InfiniteHits-item:hover {
  background: #f3f3f3;
}
.ais-InfiniteHits {
  position: fixed;
  top: 107px;
  right: 0;
  width: 324px;
  display: flex;
  flex-direction: column;
  height: 87vh;
  justify-content: flex-start;
  align-items: flex-start;
  border-left: none;
  background: #ffffff;
  overflow: scroll;
  border: 1px solid #e8e8e8;
}
.ais-Highlight-nonHighlighted {
  background: none;
  font-size: 13px;
  line-height: 19px;
  color: #313131;
  font-family: roboto;
  text-align: left;
}
.ais-Highlight-highlighted {
  background: #ffd1dd;
  font-size: 15px;
  line-height: 22px;
  color: #212121;
  font-weight: 500;
  text-align: left;
}
.ais-SearchBox-input {
  display: flex;
  justify-content: flex-start;
  min-width: 90%;
  cursor: text;
  font-size: 17px;
  padding: 10px;
  color: #7d7d7d;
  background: none;
  padding-left: 16px;
}

.ais-Pagination-list ais-Pagination-list--noRefinement {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.ais-Pagination-item ais-Pagination-item--disabled ais-Pagination-item--firstPage {
  margin-left: 5px;
  margin-right: 5px;
}
.ais-Pagination-item ais-Pagination-item--disabled ais-Pagination-item--previousPage {
  margin-left: 5px;
  margin-right: 5px;
}
.ais-SearchBox {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 36px;
  background: #fff;
  z-index: 9;
}

.ais-SearchBox ::placeholder {
  display: flex;
  justify-content: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  align-items: center;
  text-align: left;
  color: #a4a4a4;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
}
.ais-InfiniteHits-loadMore ais-InfiniteHits-loadMore--disabled {
  outline: none;
  background: none;
  border: none;
  margin-left: 23px;
  margin-bottom: 9px;
  color: #9c9c9c;
}
.ais-RefinementList ais-RefinementList {
  width: 200px;
  height: 100px;
}
.ais-InfiniteHits-loadMore {
  display: flex;
  align-self: center;
  text-align: center;
  justify-content: center;
  outline: none;
  border: none;
  cursor: pointer;
  width: 100%;
  color: #74b9f1;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-top: -9px;
}
.ais-InfiniteHits-loadMore:hover {
  background: #f3f3f3;
}
.ais-InfiniteHits-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.ais-SearchBox-submit {
  display: none;
}

.ais-SearchBox-reset {
  outline: none;
  border: none;
  color: #c5c5c5;
  background: none;
  /* background: #f5f5f5; */
  cursor: pointer;
  padding-right: 14px;
}
.react-datepicker-wrapper {
  z-index: 10;
  max-width: 85px;
}
.ant-btn {
  flex-direction: column;
  flex-flow: column;
}

/* 
 .react-datepicker__input-container {
  color: #abbed5;
  background: none;
  overflow: hidden;
  /* width: 90px;
  height: 40px;
  z-index: 1;
  cursor: pointer;
  font-size: 30px;
} 
*/
/* 
.react-datepicker-ignore-onclickoutside {
  background: none;
  z-index: 1;
  width: 90px;
  height: 40px;
  cursor: pointer
} 
.theGuyOverHere.input {
  font-size: 50px;
  
}
.react-datepicker {
  border: none;
  box-shadow: 0px 4px 4px rgba(0,0,0,0.04);
  z-index: 1;
}
.react-datepicker-popper{
  z-index: 10;
  width: 90px;
  height: 40px;
  border: 1px solid gainsboro;
}
.react-datepicker-wrapper {
  z-index: 1;
  width: 90px;
  height: 40px;
  cursor: pointer
}
.react-datepicker-ignore-onclickoutside {
  width: 90px;
  height: 40px;
  z-index: 1;
  cursor: pointer
}

.Container {
  display: flex;
  width: 100%;
  flex-direction: row;
}
*/
.searchHighlightedMoment em {
  color: #696969;
  background-color: #f9ff70;
  font-style: normal;
  break-inside: avoid;
}

.searchHighlightedTitle em {
  background-color: #f9ff70;
  font-style: normal;
  break-inside: avoid;
}
.Modal {
  display: block;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  background-color: #f5f9f9;
  flex-direction: column;
  border-radius: 6px;
  margin-top: 80px;
  margin-bottom: 80px;
  width: 825px;
  max-width: 825px;
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.1);
  z-index: +5;
}
.HeaderNav {
  color: #9b9b9b;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  height: 100%;
  width: 80px;
  margin-left: 35px;
  margin-right: 35px;
  border-bottom: 2px solid #fff;
}
/* .HeaderNav:active {
  color: #2F3948; 
  border-bottom: 2px solid #273545;
} */
.HeaderNav:visited {
  color: #9b9b9b;
}
.HeaderNav:hover {
  color: #273545;
  border-bottom: 2px solid #273545;
}
.HeaderNavInsights {
  margin-left: 30px;
  margin-right: 30px;
  padding-top: 12%;
  color: #c4d5ee;
  height: 75;
  height: 100%;
}
.backgroundWhite {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: auto;
  width: 100vw;
}
a.searchItem {
  color: #696969;
  font-style: normal;
  break-inside: avoid;
}
a.searchItem:visited {
  color: #696969;
  font-style: normal;
  break-inside: avoid;
}
.infiniteHits button {
  width: 255px;
  background-color: #fff;
  text-align: center;
  background-color: #f1f1f1;
  margin-top: 30px;
  padding: 20px;
  color: #cecece;
  font-size: 28px;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 10px;
  border-color: #d6d6d6;
  line-height: 1.7;
}
.searchStats {
  color: #bbbbbb;
  font-size: 12px;
  margin-top: 6px;
  margin-bottom: 30px;
}
.searchBox svg {
  overflow: hidden;
  fill: #b7b7b7;
  margin-top: 20px;
  width: 16px;
  height: 16px;
}
.searchBox input {
  display: block;
  border: none;
  text-align: center;
  color: #aaa;
  height: 50px;
  font-size: 22px;
  background: transparent;
  color: #595f6e;
  outline: none;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: #cccccc;
}
.ais-SearchBox-form {
  display: flex;
  width: 100%;
}

.searchBox button {
  border: none;
  background: none;
  width: 40px;
  height: 40px;
  outline: none;
  cursor: pointer;
  background-image: url(https://firebasestorage.googleapis.com/v0/b/new-theory-7c978.appspot.com/o/search.png?alt=media&token=4df4ed2c-f125-4a62-81cd-7419d6877512);
}
.searchBox ::placeholder {
  color: #cecece;
}
.searchBox form {
  display: flex;
}
.searchBox {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
