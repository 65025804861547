.CalendarDay__selected_span {
  background: #1890ff33;
  color: #58718c; 
  border: 1px double #d9d9d9; 
  
}
.CalendarDay__selected_span:hover {
  background: #62abff;
  border: 1px double #62abff;

}

.CalendarDay__selected {
  background: #1890ff9e;
  color: white;
  border: 1px double #62abff;
}


.CalendarDay__selected:hover {
  background: #62abff;
  color: white;
}
.CalendarMonth {
  background: #e7f0fd;
}
.DayPicker_transitionContainer__horizontal {
  background: #e7f0fd;
}
.CalendarMonthGrid {
  background: #e7f0fd;
}
.CalendarDay__hovered_span:hover{
  background: #62abff;
  border: 1px double #d9d9d9;
}
.CalendarDay__hovered_span {
  background: #1890ff5c;
  border: 1px double #62abff;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right: 33px solid #e1e1e1;
}
.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
  border-right: 33px solid #e1e1e1;

}
.DateInput_input__focused {
  border-bottom: 2px solid #fd638c
}

.DateInput_input {
  font-size: 15px;
  text-align: center;
  padding: 7px 11px 4px;
  width: 85px;
  font-weight: 500;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #A4A4A4;
  height: 33px;
}

.DateInput {
  width:88px; 
}
.DateRangePicker{
  width: 100%;
  margin-right: 16px;
}
.DateRangePickerInput__withBorder {
  border-left: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
  border-right: 1px solid #E0E0E0;
  width: 100%;
}